import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title : "INE Fácil",
      metaTags : [
        {
          name    : 'description',
          content : 'INE Fácil'
        },
        {
          property: 'og:description',
          content : 'INE Fácil'
        }
      ]
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/texmelucan',
    name: 'texmelucan',
    component: () => import(/* webpackChunkName: "texmelucan" */ '../views/Example01.vue')
  },
  {
    path: '/madera',
    name: 'madera',
    component: () => import(/* webpackChunkName: "madera" */ '../views/Example02.vue')
  },
  {
    path: '/chiapas',
    name: 'chiapas',
    component: () => import(/* webpackChunkName: "chiapas" */ '../views/Example03.vue')
  },
  {
    path: '/aviso-privacidad',
    name: 'privacidad',
    component: () => import(/* webpackChunkName: "privacidad" */ '../views/Privacidad.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
