<script>
import SvgIcon from '@/components/SvgIcon';
export default {
  computed : {
    section(){
      return this.$route.name;
    }
  },
  components : {
    SvgIcon
  }
}
</script>

<template>
 <div :class="section">
  <header class="hm_hd" v-if="section != 'home'">
    <div class="container">
      <div class="row">
        <div class="col-sm-4">
          <h3 class="ellogo"><router-link to="/"><b class="inefacil"></b>INE <span>Fácil</span> <span class="beta">beta</span></router-link></h3>
        </div>
        <div class="col-sm-8">
          <div id="nav" class="text-right">
            <div v-if="section == 'backend'">
              <a>Mi perfil</a> 
              <router-link to="/" class="btn_if_close">Cerrar Sesión</router-link>
            </div>
            <div v-else>
            <!--
              <router-link to="/">inicio</router-link> |-->
              <router-link to="/#demo" class="btn_if_demo">Solicita una demostración</router-link>
              
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </header>
 </div> 
  <router-view/>

 <footer>
      <div class="container">
        <div class="row">
          <div class="col-sm-4">
            <h3 class="ellogo"><router-link to="/"><b class="inefacil"></b>INE <span>Fácil</span> <span class="beta">beta</span></router-link></h3>
          </div>
          <div class="col-sm-4 offset-4">
          <p class="text-right"><a href="https://gobiernofacil.com/">Acerca de Gobierno Fácil</a> </p>
          </div>
        </div>
        </div>
        <div class="author">
        <div class="container">
        <div class="row">
          <div class="col-sm-6">
            <ul>
              <li> <a class="gobiernofacil" href="https://gobiernofacil.com">Gobierno Fácil</a> ® 2021</li>
              <li><router-link to="/aviso-privacidad">Aviso de Privacidad</router-link></li>
            </ul>            
          </div>
          <div class="col-sm-6">
            <ul class="text-right sm">
              <li><a href="https://twitter.com/gobiernofacil"><svg-icon icon="twitter" :hasFill="true"></svg-icon> <span class="sr-only">Gobierno Fácil en Twitter</span></a></li>
              <li><a href="https://www.facebook.com/GobiernoFacil"><svg-icon icon="facebook" :hasFill="true"></svg-icon> <span class="sr-only">Gobierno Fácil en Facebook</span></a></li>
              <li><a href="https://github.com/GobiernoFacil"><svg-icon icon="git" :hasFill="true"></svg-icon> <span class="sr-only">Gobierno Fácil en Github</span></a></li>
              <li><a href="https://www.youtube.com/channel/UCvFb3JoJ85qqkuV9RaKGTdg"><svg-icon icon="youtube" :hasFill="true"></svg-icon> <span class="sr-only">Gobierno Fácil en YouTube</span></a></li>
            </ul>            
          </div>
        </div>
      </div>
    </div>
    </footer>
</template>

<style>
  @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap');
  @import url('https://fonts.googleapis.com/css?family=Libre+Franklin:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap');
  @import '../node_modules/leaflet/dist/leaflet.css';
 /* @import 'assets/styles.css';*/
  #mapid { height: 600px; }
</style>
