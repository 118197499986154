
<script>
import {ref, onMounted} from "vue";
import { useReCaptcha } from 'vue-recaptcha-v3'
import SmoothScroll from "smooth-scroll";
//import validator from "validator-js"

const isEmail = require("validator").isEmail;

const name     = ref("");
const surname  = ref("");
const org      = ref("");
const email    = ref("");
const phone    = ref("");
const profile  = ref("");
const use      = ref("");
const submited = ref(false);
const errors   = ref(false);


const validation = () => {
  if(!name.value) return false;
  if(!surname.value) return false;
  if(!org.value) return false;
  if(!email.value || ! isEmail(email.value)){
    return false;
  }
  if(!phone.value){
    return false;
  }
  if(!profile.value) return false;
  if(!use.value) return false;

  return true;
}

export default {
  setup() {
    onMounted( () => {
      SmoothScroll('a[href*="#"]');
    });

    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()
    const sendUrl = 'https://contact.inefacil.com/send';
    // const sendUrl = "http://localhost:3000/send"

    const recaptcha = async (e) => {
      e.preventDefault();
      errors.value = validation();

      await recaptchaLoaded()
      const token = await executeRecaptcha('login')

      const data = {
        name    : name.value,
        surname : surname.value,
        org     : org.value,
        email   : email.value,
        phone   : phone.value,
        profile : profile.value,
        use     : use.value,
        token,
      }

      fetch(sendUrl, {
        headers : {
          'Accept' : 'application/json',
          'Content-type' : 'application/json'
        },
        method : 'post',
        body : JSON.stringify(data)
      })
      .then(d =>  d.json())
      .then(json =>{
        if(json.success){
          submited.value = true;
        }
      })
      .catch(error => console.log("error:", error));
    }

    return {
      recaptcha,

      // form values
      name,
      surname,
      org,
      email,
      phone,
      profile,
      use,

      submited,
      errors,
      validation
    }
  }
}
</script>
<template>
  <!-- eslint-disable vue/no-multiple-template-root -->
  <div class="el_hero">
    <div class="container">
    <div class="row">
          <div class="col-sm-4">
            <h3 class="ellogo"><a><b class="inefacil"></b>INE <span>Fácil</span> <span class="beta">beta</span></a></h3>
          </div>
         
        </div>
      <div class="row">
        <div class="col-sm-9">
          <h1 class="el_title_h ">La manera más fácil y rápida de analizar datos electorales en México</h1>
        </div>
         <div class="col-sm-9">
          <p class="el_lead mt-4">Diferentes fuentes de información para cambiar la manera en la que son entendidas las elecciones.</p>
          <p class=""><a href="https://mexicoendatos.com/" class="btn_front">Usa la API de México en Datos</a></p>
        </div>
      </div>
    </div>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" preserveAspectRatio="none" viewBox="0 0 1680 40" class="position-absolute width-full z-1" style="bottom: -1px;"><path d="M0 40h1680V30S1340 0 840 0 0 30 0 30z" fill="#fff"></path></svg>
  </div>

  <section>
  <div class="container mt-4">
    <div class="row">
      <div class="col-sm-4 mt-4 pt-4">
        <h3 class="elf-ti">+3,000 elecciones</h3>
        <p>Datos de resultados de elecciones a nivel estatal, municipal, por distrito y sección.</p>
      </div>
      <div class="col-sm-8">
        <img src="/images/elecciones.png"/>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-8">
        <img src="/images/gastos.png"/>
      </div>
      <div class="col-sm-4 mt-4 pt-4">
        <h3 class="elf-ti">Gastos en campaña</h3>
        <p>Consulta los gastos e ingresos de los candidatos en la última elección.</p>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-4 mt-4 pt-4">
        <h3 class="elf-ti">Comparaciones</h3>
        <p>Datos de resultados de elecciones a nivel estatal, municipal, por distrito y sección.</p>
      </div>
      <div class="col-sm-8">
        <img src="/images/comparaciones.png"/>
      </div>
    </div>

  </div>

  
  </section>
  <section class="el_request" id="demo">
  <div class="container">
    <div class="row mb-4">
        <div class="col-12">
          <h2>¿Quieres crear aplicaciones como esta o usar los datos?</h2>
          <p class=""><a href="https://mexicoendatos.com/" class="btn_front">Usa la API de México en Datos</a></p>
         </div>
     </div>
  </div>
  <!--
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" preserveAspectRatio="none" viewBox="0 0 1680 40" class="position-absolute top-0 width-full mt-n1-fluid" style="transform: scaleY(-1);"><path d="M0 40h1680V30S1340 0 840 0 0 30 0 30z" fill="#fff"></path></svg>
    <transition name="fade">
    <div class="container" v-if="!submited">
      <div class="row mb-4">
        <div class="col-12">
          <h2>Solicita una demostración</h2>
          <h3 v-if="submited && errors">Por favor completa los campos requeridos</h3>
        </div>
      </div>
      <form v-on:submit="recaptcha">
        <div class="row">
          <div class="col-sm-6">
            <p>Nombre*</p>
            <input type="text" required v-model="name" />
          </div>
          <div class="col-sm-6">
            <p>Apellidos*</p>
            <input type="text" required v-model="surname"/>
          </div>
        </div> 
        <div class="row mt-4">
          <div class="col-sm-6">
            <p>Organización*</p>
            <input type="text" required v-model="org" />
          </div>
          <div class="col-sm-6">
            <p>Correo*</p>
            <input type="email" required v-model="email" />
          </div>
        </div>  
        <div class="row mt-4">
          <div class="col-sm-6">
            <p>Teléfono*</p>
            <input type="text" required v-model="phone" />
          </div>
          <div class="col-sm-6">
            <p>¿Cuál es tu perfil?*</p>
            <select v-model="profile" required>
              <option value="">Seleccionar</option>
              <option>Estudiante</option>
              <option>Investigador</option>
              <option>Periodista</option>
              <option>Partido Político</option>
              <option>Candidato</option>
            </select>
          </div>
          </div> 
          <div class="row mt-4">
            <div class="col-sm-12">
              <p>¿Cómo piensas usar la herramienta?*</p>
              <textarea required v-model="use"></textarea>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-sm-6">
            <p>Al enviar tu información personal aceptas nuestro <router-link to="/aviso-privacidad">aviso de privacidad</router-link>.</p>
            </div>
            <div class="col-sm-6">
            <p> <input type="submit" value="Enviar"></p>-->
            <!--<p><button @click="recaptcha">Execute recaptcha</button></p>-->
            <!-- </div>
          </div>          
      </form>
    </div>
    </transition>-->

      <!---
      Mensaje
      !-->
    <transition name="fade">
    <div class="container" v-if="submited">
      <div class="row mt-4 pt-4">
        <div class="col-12 mt-4 pt-4">
          <h2 class="mt-4 pt-4">Tu mensaje ha sido enviado.</h2>
          <p>Gracias por contactarnos, espera noticias nuestras pronto. </p>
          <h1>:)</h1>
        </div>
      </div>
    </div>
    </transition>
      <!---
      se acaba Mensaje
      ______________________________________________________!-->
    
  </section>
    
</template>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter-to, .fade-leave-to{
  opacity: 0;
}
</style>
